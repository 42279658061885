<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn @click="markAllNotificationsAsRead()" outlined color="primary"
          >Marca todas como lidas</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12">
        <app-pagination @click="select($event)" :data="notifications">
          <v-list two-line>
            <v-list-item-group>
              <template v-for="(notification, index) in notifications.data">
                <v-list-item
                  @click="markNotificationAsRead(notification)"
                  dense
                  :key="notification.id"
                >
                  <template>
                    <v-list-item-action>
                      <v-icon
                        :color="notification.data.type"
                        v-text="notification.data.icon"
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-medium"
                        v-text="notification.data.title"
                      />

                      <v-list-item-subtitle
                        v-text="notification.data.message"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text
                        v-text="$format.dateRelative(notification.created_at)"
                      ></v-list-item-action-text>
                      <v-icon v-text="readIcon(notification.read_at)" />
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < notifications.data.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </app-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    page: 1,
  }),

  mounted() {
    this.select();
  },

  computed: {
    notifications() {
      return this.$store.state.notification.notifications;
    },
  },
  methods: {
    readIcon(read_at) {
      return read_at ? "mdi-email-open" : "mdi-email";
    },

    markAllNotificationsAsRead() {
      this.$loading.start();
      this.$store
        .dispatch("notification/markAllNotificationsAsRead")
        .then((response) => {
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    markNotificationAsRead(notification) {
      if (notification.read_at == null) {
        this.$loading.start();
        this.$store
          .dispatch("notification/markNotificationAsRead", notification.id)
          .then((response) => {
            this.select();
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },

    select(page = 1) {
      this.$loading.start();
      this.$store
        .dispatch("notification/getNotifications", page)
        .then((response) => {
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style scoped></style>
